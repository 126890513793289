






















































































import { api } from "@/api/api";
import { ApiGetWorkingHourDto } from "@/api/generated/Api";
import BaseCol from "@/components/shared/form/BaseCol.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper, onParamChange } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRouteComputed, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";

export default defineComponent({
  name: "CourseWorkingHoursDetailsPage",
  components: { BaseSheet, BaseSheetHeader, BaseCol, BaseSheetField },
  setup() {
    const route = useRouteComputed();
    const router = useRouter();
    const store = useStore<StoreState>();
    const courseName = computed(() => store.state.courses.course.courseName);
    const workingHourEntry = ref<ApiGetWorkingHourDto>();

    const loadHourRegistered = async () => {
      const getworkingHourEntryResponse = (await api.economy.getWorkingHourAsync(+route.value.params.hourEntryId)).data;
      workingHourEntry.value = getworkingHourEntryResponse;
    };

    const approveWorkingHourEntry = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!workingHourEntry.value) {
          return;
        }
        await api.economy.approveWorkingHourAsync([workingHourEntry.value.id]);
        openNotification(store, NotificationItemType.Success, "Arbeidstimer godkjent");
        await loadHourRegistered();
      });
    };

    const declineWorkingHourEntry = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!workingHourEntry.value) {
          return;
        }
        await api.economy.declineWorkingHourAsync([workingHourEntry.value.id]);
        openNotification(store, NotificationItemType.Success, "Arbeidstimer avvist");
        await loadHourRegistered();
      });
    };

    const navigateBackToWorkingHoursList = () =>
      router.push({
        name: SingleCourseRouteNames.CourseWorkingHours,
        params: {
          id: route.value.params.id,
        },
      });

    onParamChange(loadHourRegistered);

    return {
      courseName,
      workingHourEntry,
      isApproved: computed(() => workingHourEntry.value?.status === WorkingHourStatusEnum.PaymentPending),
      isPendingApproval: computed(() => workingHourEntry.value?.status === WorkingHourStatusEnum.NotApproved),
      approveWorkingHourEntry,
      declineWorkingHourEntry,
      formatLocalizedDate,
      navigateBackToWorkingHoursList,
    };
  },
});
